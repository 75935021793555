<template>
	<a @click="openBookingEngine({ ...props })">
		<slot />
	</a>
</template>

<script setup>
defineProps({
	roomid: { type: Number, default: 0 },
	rateid: { type: String, default: '' },
});

const openBookingEngine = ({ roomid: roomTypeIds, rateid: rateIds }) => {
	window.clockPmsWbeShow && window.clockPmsWbeShow({ roomTypeIds: [roomTypeIds], rateIds: [rateIds] });
};
</script>

<style lang="scss" scoped>
a {
	color: #fff;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}
</style>
